<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
          <b-container fluid>
            <b-row class="mb-4">
              <b-col>
                <b-card>
                  <p><b-link :to="{ name: 'ImpactTopics' }">all topics</b-link></p>
                  <h2>
                    <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: result.topic.id, model: 'impacttopic' }}">Edit</b-button>
                    {{result.topic.name}}
                  </h2>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card>
                  <div v-for="initiative in result.topic.impactinitiatives" :key="'initiative'+initiative.id" class="my-4">
                    <h4><router-link :to="{ name: 'ImpactInitiative', params: { id: initiative.id }}"><h4 class="card-title">{{initiative.name}}</h4></router-link></h4>
                    <div>{{initiative.country}} | {{initiative.impactstatus.name}}</div>
                    <div>{{initiative.headline}}</div>
                  </div>
                </b-card>
              </b-col>
              <b-col v-if="result.publications.length > 0">
                <b-card class="mb-3" v-for="item in result.publications" :key="'publication-' + item.id">
                  <publication
                    :hideLink="false"
                    :propItem="item"
                    :key="item.id"
                    :username="user.username"
                    :tagsProp="[]"
                    :permissionAddArticle="false"
                    :permissionAddRisk="false"
                    :permissionAddTag="false"
                    :permissionArticlesWip="false"
                    :permissionDeletePublication="false"
                    :permissionReadTagsPanel="false"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import Publication from '@/components/PublicationNew'

export default {
  name: 'ImpactTopic',
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact topic', action: 'open impact topic', model: 'impacttopic', model_id: this.$route.params.id })
    this.permission.edit = ac.can(this.user.acgroups).updateAny('impacttopic').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      result: null,
      permission: {
        edit: false
      }
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/impact/topic/dash/${this.$route.params.id}`
        this.result = await this.$Amplify.API.get(apiName, path)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
